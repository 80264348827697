/******************************************************************************/
/* DUOTONE ICONS                                                              */
/******************************************************************************/

@each $color, $value in $theme-colors {
  .fad-color-primary-#{$color} {
    --fa-primary-color: #{$value};
  }
  .fad-color-secondary-#{$color} {
    --fa-secondary-color: #{$value};
  }
}

@each $hbm-opacity-value in $hbm-opacity-values {
  .fad-opacity-primary-#{$hbm-opacity-value} {
    --fa-primary-opacity: #{$hbm-opacity-value/100};
  }
  .fad-opacity-secondary-#{$hbm-opacity-value} {
    --fa-secondary-opacity: #{$hbm-opacity-value/100};
  }
}
