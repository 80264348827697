/******************************************************************************/
/* SYNDICATON                                                                 */
/******************************************************************************/

/* Used in "check connection" flash message. */
.syndication-dsn {
  font-family: $font-family-monospace;
  @include font-size($code-font-size);
  color: $code-color;
}

/* Used in explain flash message. */
.syndication-path {
  .syndication-folder {
    opacity: 0.5;
    letter-spacing: 0.6px;
    margin-right: 2px;
  }
}

/* Used in explain flash message. */
.syndication-content-label {
  font-style: italic;
  font-size: 0.8em;
  margin-left: 2px;
}

/******************************************************************************/

/* Used in notice. */
.syndication-content {
  font-style: italic;
  font-size: 0.8em;
}

/* Used in notice. */
.syndication-filename {
  font-family: $font-family-monospace;
  @include font-size($code-font-size);
  color: $code-color;

  .syndication-folder {
    opacity: 0.5;
    margin-right: 2px;
    letter-spacing: 0.6px;
    color: darken($code-color, 15%);
  }
}

/* Used in notice. */
.syndication-partner {
  font-weight: bold;
}

/******************************************************************************/

/* Used in syndication listing. */
.syndication-replacement {
  font-family: $font-family-monospace;
  @include font-size($code-font-size);
  color: $code-color;
  border: 1px solid $border-color;
  border-radius: 3px;
  padding-left: 1px;
  padding-right: 1px;
  margin-left: 1px;
  margin-right: 1px;
}

.syndication-files {
  > span {
    margin: 0 1.5px;
  }

  &.syndication-archive {
    background-color: lighten(color('blue'), 10%);
    padding: 2px 1.5px;
  }
}
