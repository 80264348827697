/******************************************************************************/
/* FORM                                                                       */
/******************************************************************************/

.btn-group.input-group-prepend {
  > .btn {
    border-radius: 0;
  }
}

.btn-group.input-group-prepend:last-child {
  > .btn:last-child {
    @include border-right-radius($input-border-radius);
  }
}

.btn.btn-input-group-addon {
  color: $input-group-addon-color;
  background-color: $input-group-addon-bg;
  border: $input-border-width solid $input-group-addon-border-color;

  &.focus,
  &:focus {
    box-shadow: none;
  }

  &.active {
    color: $hbm-color-highlight;
  }
}

.btn-group.input-group-prepend.large-icons {
  .btn.btn-input-group-addon {
    > .fa,
    > .svg-inline--fa {
      font-size: 1.2rem;
    }
  }
}

@mixin input-group-item-variant($variant, $background, $color) {
  .input-group-#{$variant} {
    color: $color;
    background-color: $background;

    .input-group-text {
      background-color: darken($background, 15%);
    }
  }

  .hbm-form-group .hbm-form-controls .hbm-form-input-groups .input-group.input-group-#{$variant} label {
    background-color: transparent;
  }
}

@each $color, $value in $theme-colors {
  @include input-group-item-variant($color, theme-color-level($color, -9), theme-color-level($color, 6));
}


/******************************************************************************/

.hbm-form-buttons {
  text-align: center;
  margin-top: 1rem;

  .btn-block {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  > button + button {
    margin-left: 0.75rem;
  }

  > button.btn-block + button.btn-block {
    margin-left: 0;
    margin-top: 0.75rem;
  }
}

.hbm-form-highlight-label {
  label {
    font-weight: bold;
    color: $hbm-color-highlight;
  }
}

.hbm-disabled {
  opacity: 0.65;
  cursor: not-allowed;
  pointer-events: none;
}

.hbm-form-highlight-control {
  textarea, input, select {
    background-color: lighten($hbm-color-secondary, 10%);
  }
}

.hbm-form-card {
  &.card > .card-header.hbm-form-header {
    font-size: 1.5rem;

    label {
      margin: 0;
    }
  }

  &.card > .card-body.hbm-form-body {
    &.hbm-form-header-text {
      background-color: $card-cap-bg;
      border-bottom: $card-border-width solid $card-border-color;
    }
    .card-subtitle {
      padding-bottom: 1rem;
      border-bottom: 1px solid $card-border-color;
      margin-bottom: 1rem;

      @include reasonable-paragraphs-margins($hbm-resonable-paragraphs-margin);
    }
    .card-separator {
      padding-top: 1rem;
      padding-bottom: 1rem;
      border-top: 1px solid $card-border-color;
      border-bottom: 1px solid $card-border-color;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
    .card-separator-header {
      padding: $card-spacer-y $card-spacer-x;
      margin-top: 1rem;
      margin-bottom: 1rem;
      margin-left: -$card-spacer-x;
      margin-right: -$card-spacer-x;
      color: $card-cap-color;
      background-color: $card-cap-bg;
      border-top: 1px solid $card-border-color;
      border-bottom: 1px solid $card-border-color;
    }
    .card-divider {
      padding-bottom: 2rem;
      border-bottom: 1px solid $card-border-color;
      margin-bottom: 2rem;
    }
  }
}

.hbm-form-group {
  margin-bottom: 0;

  & + & {
    margin-top: 1rem;
  }

  label.required:not(.form-control):not(.form-control-handle) {
    &:after {
      content: '*';
    }
  }

  input[type="radio"].form-control,
  input[type="checkbox"].form-control {
    width: auto;
  }

  .hbm-form-controls {

    .form-control {
      height: auto;
    }

    .hbm-form-label-inline {
      margin: 0;

      padding-left: 0.5rem;
    }

    .hbm-form-help {
      > ul {
        padding-left: 2rem;
        margin-bottom: 0;
      }
    }

    .hbm-form-input-groups {
      padding: 0;
      margin: 0;

      &.header-center {
        .input-group {
          .input-group-header {
            .input-group-text {
              text-align: center;
              justify-content: center;
            }
          }
        }
      }

      .input-group {
        list-style: none;

        .input-group-header {
          width: 100%;
          .input-group-text {
            font-weight: bold;
            white-space: normal;
            text-align: left;
            justify-content: flex-start;
          }
        }

        .input-group-prepend, .input-group-append, .input-group-header {
          .input-group-text {
            border-top: 0 solid #CCCCCC;
            border-radius: 0;
          }
        }

        &:first-child {
          .input-group-prepend, .input-group-append, .input-group-header {
            .input-group-text {
              border-top: 1px solid #CCCCCC;
              border-top-left-radius: $input-border-radius;
              border-top-right-radius: $input-border-radius;
            }

            &:first-child:not(:last-child) {
              .input-group-text {
                border-top-right-radius: 0;
              }
            }
            &:last-child:not(:first-child) {
              .input-group-text {
                border-top-left-radius: 0;
              }
            }
          }
        }

        &:last-child {
          .input-group-prepend, .input-group-append, .input-group-header {
            .input-group-text {
              border-bottom-left-radius: $input-border-radius;
              border-bottom-right-radius: $input-border-radius;
            }

            &:first-child:not(:last-child) {
              .input-group-text {
                border-bottom-right-radius: 0;
              }
            }
            &:last-child:not(:first-child) {
              .input-group-text {
                border-bottom-left-radius: 0;
              }
            }
          }
        }

        label {
          display: block;
          padding: .375rem .75rem;
          margin: 0;
          font-weight: normal;
          font-size: 1rem;
          line-height: 1.5;
          background-color: #fff;
          background-clip: padding-box;
          border-top: 0 solid #CCCCCC;
          border-left: 1px solid #CCCCCC;
          border-right: 1px solid #CCCCCC;
          border-bottom: 1px solid #CCCCCC;
          border-radius: 0;
          flex-grow: 1;

          &.form-control-handle {
            position: relative;
            flex: 1 1 auto;
            width: 1%;
            min-width: 0;
          }
        }

        &:first-child {
          label {
            border-top: 1px solid #CCCCCC;
            border-top-left-radius: $input-border-radius;
            border-top-right-radius: $input-border-radius;

            &:first-child:not(:last-child) {
              border-top-right-radius: 0;
            }
            &:last-child:not(:first-child) {
              border-top-left-radius: 0;
            }
          }
        }

        &:last-child {
          label {
            border-bottom-left-radius: $input-border-radius;
            border-bottom-right-radius: $input-border-radius;

            &:first-child:not(:last-child) {
              border-bottom-right-radius: 0;
            }
            &:last-child:not(:first-child) {
              border-bottom-left-radius: 0;
            }
          }
        }
      }
    }

    > .hbm-input-checkbox {
      width: auto;
      display: table;
    }

    > textarea {
      height: auto;
    }
  }

}

.form-inline .input-group {
  align-items: center;
  display: flex;
}
