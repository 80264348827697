@mixin inherit-text-styles {
  line-height: inherit;
  text-transform: inherit;
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
}

@mixin font-size($font-size) {
  font-size: $font-size;
  @if ($font-size <= 10) {
    line-height: 1.25;
  } @else if ($font-size <= 15) {
    line-height: 1.15;
  } @else {
    line-height: 1;
  }
}
