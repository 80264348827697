/******************************************************************************/
/* COLORS                                                                     */
/******************************************************************************/

.hbm-color-fill-primary {
  fill: $hbm-color-highlight;
}

.hbm-color-bg-primary {
  background-color: $hbm-color-highlight;
}

.hbm-color-fill-secondary {
  fill: $hbm-color-secondary;
}

.hbm-color-bg-secondary {
  background-color: $hbm-color-secondary;
}

.hbm-color-fill-logo {
  fill: $hbm-color-logo;
}

.hbm-color-bg-logo {
  background-color: $hbm-color-logo;
}

/******************************************************************************/

@each $name, $color in $hbm-colors {
  .hbm-color {
    &.#{$name} {
      color: $color;
    }
  }

  .hbm-color-fill {
    &.#{$name} {
      fill: $color;
    }
  }

  .hbm-color-bg {
    &.#{$name} {
      background-color: $color;
    }
  }
}
