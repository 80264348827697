/******************************************************************************/
/* DATAGRID                                                                   */
/******************************************************************************/

.hbm-table-wrapper,
.datagrid-table-wrapper {
  width: 100%;
  overflow-x: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
