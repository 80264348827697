/******************************************************************************/
/* VARIABLES                                                                  */
/******************************************************************************/

$hbm-font-size: 14px;
$hbm-font-family: 'Arial', 'Neue Helvetica', 'Helvetica', sans-serif;

// Prepare @burdadirect/hbm-styles variables
$burda-color: #2AB7AD;

$hbm-color-bg: #e5e5e5;
$hbm-color-text: #260211;
$hbm-color-logo: saturate(lighten($burda-color, 5%), 30%);
$hbm-color-highlight: darken(saturate($burda-color, 10%), 5%);
$hbm-color-secondary: lighten($burda-color, 30%);

$hbm-toggable-items: ('ids', 'folders');

$hbm-table-cell-widths: (10, 20, 25, 30, 40, 50, 60, 70, 75, 80, 90, 100, 125, 150, 175, 200, 225, 250);
$hbm-opacity-values: (10, 20, 30, 40, 50, 60, 70, 80, 90, 100);

/******************************************************************************/

// Prepare bootstrap variables
$theme-colors: (
  primary: $hbm-color-highlight,
  secondary: $hbm-color-secondary,
  secondary-light: desaturate(lighten($hbm-color-secondary, 15%), 10%),
);

$grid-gutter-width: 30px;
$spacers: (
  'grid': $grid-gutter-width,
);

$font-family-base: $hbm-font-family;
$headings-font-family: $hbm-font-family;

$body-color: $hbm-color-text;

$breadcrumb-divider: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4IiBoZWlnaHQ9IjgiPjxwYXRoIGQ9Ik0yLjUgMEwxIDEuNSAzLjUgNCAxIDYuNSAyLjUgOGw0LTQtNC00eiIgZmlsbD0iY3VycmVudENvbG9yIi8+PC9zdmc+);

/******************************************************************************/

$border-radius:               0rem !default;
$border-radius-lg:            0rem !default;
$border-radius-sm:            0rem !default;

$input-border-radius:         0rem !default;
$input-border-radius-lg:      0rem !default;
$input-border-radius-sm:      0rem !default;

$jumbotron-bg: $hbm-color-bg;
