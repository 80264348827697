/******************************************************************************/
/* CARD                                                                       */
/******************************************************************************/

.card > .card-body > .card-title a,
.card > .card-body > .card-subtitle a{
  font-size: inherit;
  color: inherit;
  &:hover{
    text-decoration: none;
  }
}

.card > .card-header,
.card > .card-body {
  > h1, h2, h3, h4, h5, h6 {
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}
