/******************************************************************************/
/* MIXINS                                                                     */
/******************************************************************************/

@mixin reasonable-paragraphs-margins($margin: 1rem) {
  p {
    margin-top: $margin;
    margin-bottom: $margin;
  }
  p:first-child {
    margin-top: 0;
  }
  p:last-child {
    margin-bottom: 0;
  }
}
