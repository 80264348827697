@import 'variables';
@import '../../../../node_modules/bootstrap/scss/functions';
@import '../../../../node_modules/bootstrap/scss/variables';

// Should be:
// 'primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark'
// 'blue', 'indigo', 'purple', 'pink', 'red', 'orange', 'yellow', 'green', 'teal', 'cyan', 'white', 'gray', 'gray-dark',
// '100', '200', '300', '400', '500', '600', '700', '800', '900',

$theme-colors: map-merge($theme-colors, map-merge($colors, $grays));

html {
  font-size: $hbm-font-size;
  font-family: $hbm-font-family;
}

@import '../../../../node_modules/bootstrap/scss/bootstrap';
@import '../../../../node_modules/@ttskch/select2-bootstrap4-theme/src/select2-bootstrap4.scss';
@import '../../../../node_modules/@burdadirect/hbm-frontend/scss/hbm-styles';

@import '../../../includes/styles/fonts';
@import '../../../includes/styles/mixins';

@import 'alerts';
@import 'anchors';
@import 'buttons';
@import 'card';
@import 'colors';
@import 'content';
@import 'dashboard';
@import 'duotone-icons';
@import 'forms';
@import 'images';
@import 'layout';
@import 'list-group';
@import 'misc';
@import 'select2';
@import 'syndication';
@import 'tables';



