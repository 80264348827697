/******************************************************************************/
/* VARIABLES                                                                  */
/******************************************************************************/

$hbm-font-family: 'Arial', 'Neue Helvetica', 'Helvetica', sans-serif !default;

$hbm-colors: () !default;

$hbm-color-text:       $gray-900 !default;
$hbm-color-logo:       $primary !default;
$hbm-color-highlight:  $primary !default;
$hbm-color-secondary:  $secondary !default;

$hbm-color-steps:      #CCC !default;

$hbm-toggable-items: () !default;
$hbm-table-cell-widths: (10, 25, 50, 75, 100, 125, 150, 200, 250) !default;

$hbm-resonable-paragraphs-margin: 1rem !default;

/******************************************************************************/

$table-hover-bg: rgba(0,0,0,.045);
$table-accent-bg: $table-hover-bg;
