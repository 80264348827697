/******************************************************************************/
/* SELECT2-BOOTSTRAP4-THEME                                                    */
/******************************************************************************/

.select2-container--bootstrap4 {
  .select2-search--dropdown .select2-search__field {
    border-color: theme-color("primary");
    background-color: theme-color("secondary-light");
  }

  .select2-selection__clear {
    width: 1.3rem;
    height: 1.3rem;
    line-height: 1.2rem;
    text-align: center;
    padding-left: 0;
    margin-right: 0;
    margin-top: 0.5rem;
    right: 4px;

    color: #fff;
    background-color: theme-color("primary");

    &:hover {
      background-color: darken(theme-color("primary"), 7.5%);
    }
  }

  .select2-results > {
    .select2-results__options {
      max-height: calc(100vh - 550px);

      .select2-results__option {
        &[aria-selected="true"]:not(.select2-results__option--highlighted) {
          color: $hbm-color-text;
          background-color: theme-color("secondary-light");
        }
        &.select2-results__option--highlighted {
          color: #fff;
          background-color: theme-color("primary");
        }
      }
    }
  }

  .select2-selection--multiple {
    .select2-selection__choice {
      @include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-line-height, $btn-border-radius);

      width: 100%;
      color: $hbm-color-text;
      background-color: theme-color("secondary");

      .select2-selection__choice__remove {
        margin: 0 $btn-padding-x 0 0;
        color: theme-color("primary");
      }
    }

    .select2-search.select2-search--inline {
      width: 100%;
      background-color: theme-color("100");
      border: 1px dashed theme-color("400");
      margin-top: 4px;

      .select2-search__field {
        background-color: transparent;
        margin: 0;
        @include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-line-height, $btn-border-radius);
        box-sizing: content-box;
      }
    }
  }
}
