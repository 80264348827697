/******************************************************************************/
/* TREE - https://jsfiddle.net/1fynun7a/1/                                    */
/******************************************************************************/

ul.tree {
  list-style: none;
  margin: 0;
  padding: 0;

  & ul {
    list-style: none;
    margin: 0 0 0 10px;
    padding: 0;
  }

  li {
    position: relative;
    margin: 0;
    padding: 0 0 0 1.1em;
    border-left:1px solid rgb(100,100,100);

    &:last-child {
      border-left: none;
      padding-left: calc(1.1em + 1px);
    }
    &:before {
      position: absolute;
      top: -0.3em;
      left: 0;
      height: 1em;
      width: 1em;
      color: white;
      border-bottom: 1px solid rgb(100,100,100);
      content: "";
    }
    &:last-child:before {
      width: calc(1em + 1px);
      border-left: 1px solid rgb(100,100,100);
    }
  }
}
