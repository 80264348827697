/******************************************************************************/
/* LIST-GROUP                                                                 */
/******************************************************************************/

.list-group.list-group-flush {
  .list-group-item.list-group-border-top {
    border-top: $list-group-border-width solid $list-group-border-color;
  }
}

.list-group.list-group-flush.list-group-border-top {
  .list-group-item:first-child {
    border-top: $list-group-border-width solid $list-group-border-color;
  }
}
