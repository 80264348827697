/******************************************************************************/
/* STEPS                                                                      */
/******************************************************************************/

ul.steps {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;

  li.step {
    flex: 1;
    text-align: center;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 1.5em;
      left: 0;
      right: 0;
      border-top: 2px solid $hbm-color-steps;
      box-sizing: border-box;
    }

    .step-icon {
      display: inline-flex;
      position: relative;

      border: 2px solid $hbm-color-steps;
      background-color: #fff;
      border-radius: 50%;
      padding: 0.5em;
    }

    .step-icon-only {
      display: inline-flex;
    }

    .step-text {
      display: block;
      padding: 1em;
    }

    .step-color {
      color: $hbm-color-steps;
    }
  }
}

/******************************************************************************/
/* SECTIONS                                                                   */
/******************************************************************************/

ul.sections {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;

  li.step {
    flex: 1;
    text-align: center;
    position: relative;

    &:not(:last-child) {
      border-right: $border-width solid $hbm-color-steps;
    }

    .step-icon {
      display: inline-flex;
      position: relative;

      background-color: #fff;
    }

    .step-icon-only {
      display: inline-flex;
    }

    .step-text {
      display: block;
    }

    .step-color {
      color: $hbm-color-steps;
    }
  }
}
