/******************************************************************************/
/* COLORS                                                                     */
/******************************************************************************/

@mixin hbmep-bg-variant($class, $color) {
  #{$class} {
    background-color: lighten(map-get($color, 'light'), 10%) !important;
  }
  a#{$class},
  button#{$class} {
    @include hover-focus {
      background-color: darken(map-get($color, 'light'), 10%) !important;
    }
  }

  .card#{$class} > .card-header {
    background-color: lighten(map-get($color, 'light'), 0%) !important;
  }
}

$hbmep-colors: (
  'cyan': (
    'orig': #17b6f1,
    'light': #95ddf8,
  ),
  'orange': (
    'orig': #fd7e14,
    'light': #fec494,
  ),
  'teal': (
    'orig': #20c997,
    'light': #99e6cf,
  ),
  'yellow': (
    'orig': #ffc107,
    'light': #ffe28e,
  ),
  'pink': (
    'orig': #e83e8c,
    'light': #f4a7ca,
  ),
  'brown': (
    'orig': #a53f2b,
    'light': #d6a79e,
  ),
  'indigo': (
    'orig': #6610f2,
    'light': #b992f9,
  ),
  'gray': (
    'orig': #adb5bd,
    'light': #d9dde1,
  ),
);

@each $key, $value in $hbmep-colors {
  @include hbmep-bg-variant(".hbmep-bg-#{$key}", $value);
}

@each $key, $value in $hbmep-colors {
  .hbmep-border-#{$key} {
    border-color: map-get($value, 'orig') !important;
  }
}
