/******************************************************************************/
/* ANCHORS                                                                    */
/******************************************************************************/

.anchor {
  .anchor-handle {
    display: none;
    opacity: 0.8;
  }

  &:hover, &.hover {
    .anchor-handle {
      display: initial;
    }
  }
}

div#quicknav-container {
  position: sticky;
  top: 0;
  z-index: 1000;
  height: 0;
  text-align: right;

  ul#quicknav {
    display: inline-flex;
    font-size: 0.8rem;

    list-style: none;
    background-color: rgba(theme-color('secondary'), 0.8);

    box-shadow: 1px 1px 5px #222;
    margin: 0;
    padding: 0;

    > li {
      padding: 0;

      .btn {
        font-size: 1em;
        padding: 6px 4px;
        line-height: 1;
        filter: drop-shadow(1px 1px 0px #000);

        @extend .no-focus;
        @extend .no-outline;
      }
    }
  }
}
