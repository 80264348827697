/******************************************************************************/
/* FORMS                                                                      */
/******************************************************************************/

ul.hbmep-form-errors {
  font-size: 1rem;
}

/******************************************************************************/
/* SELECT2-BOOTSTRAP4-THEME                                                    */
/******************************************************************************/
.select2-container--bootstrap4 {
  .select2-selection--single {
    border-radius: 0;
  }

  .select2-dropdown {
    border-radius: 0;
    border-color: $burda-color;
  }

  .select2-selection--single:focus {
    border: 1px solid $burda-color;
  }

  .select2-results__option--highlighted[aria-selected] {
    background-color: $burda-color;
  }

  .select2-search--dropdown .select2-search__field {
    border-radius: 0;
  }

  &.select2-container--open {
    .select2-selection {
      border-color: $burda-color;
    }
  }

  .select2-results > .select2-results__options {
    max-height: calc(100vh - 550px);
  }
}
