/******************************************************************************/
/* Tables                                                                     */
/******************************************************************************/

td,th {
  @each $hbm-table-cell-width in $hbm-table-cell-widths {
    &.fixed-#{$hbm-table-cell-width} {
      width: #{$hbm-table-cell-width}px;
    }
  }
}

.sticky-table-header-indicator {
  display: none;
}

body.sticky-table-headers {
  // Position "sticky" on th will not work, if any parent has the overflow property set.
  .hbm-table-wrapper,
  .datagrid-table-wrapper {
    overflow-x: initial !important;
  }

  table {
    position: relative;

    thead.sticky-table-header {
      .sticky-table-header-indicator {
        display: initial;
        position: absolute;
        top: 1px;
        left: 1px;
        z-index: 2;
        font-size: 0.6em;
        color: theme-color('danger');
      }

      tr {
        th {
          position: sticky;
          top: 0;
          z-index: 1;

          color: $table-head-color;
          background-color: $table-head-bg;
          border-color: $table-border-color;
        }
      }
    }
  }
}
