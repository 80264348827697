/******************************************************************************/
/* CONTENT                                                                    */
/******************************************************************************/

.hotline {
  text-align: center;
}

.content {
  @include reasonable-paragraphs-margins(0.5rem);
}

.abonnement-partner {
  line-height: 1;

  a.abonnement-partner-image {
    display: inline-block;
    max-width: 25%;

    img {
      @include img-fluid();
      max-height: 70px;
    }
  }

  a.abonnement-partner-name {
    @include font-size($small-font-size);
  }
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
