/******************************************************************************/
/* COMMON                                                                     */
/******************************************************************************/

.highlight {
  color: $hbm-color-highlight;
}

.text-normal {
  font-size: 1rem;
}

@each $percent in (90, 80, 70, 60, 50, 40, 30, 20) {
  .mini-#{$percent} {
    font-size: percentage($percent/100);
  }
}

mark {
  background-color: yellow;
  display: inline-block;
}

code.pre {
  white-space: pre;
}

.reasonable-paragraph-margins {
  @include reasonable-paragraphs-margins($hbm-resonable-paragraphs-margin);
}

.valign-center {
  vertical-align: middle !important;
}
