/******************************************************************************/
/* ALERTS                                                                     */
/******************************************************************************/

.hbm-alerts {
  list-style: none;
  padding: 0;

  font-size: 2rem;

  li.alert {
    margin: 5px 0;
    padding: 5px 15px;

    .normal {
      font-size: 1rem;
    }
  }

  &:last-of-type {
    margin-bottom: 20px;
  }
}

.hbm-form-controls {
  .hbm-alerts {
    font-size: 1rem;
  }
}
