/******************************************************************************/
/* IMAGES                                                                     */
/******************************************************************************/
.brand-logo,
.brand-logo-small {
  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px solid #CCCCCC;
  background-color: #EFEFEF;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.brand-logo {
  padding: 10px;
  width: 170px;
  height: 90px;
}

.brand-logo-small {
  padding: 5px;
  width: 85px;
  height: 45px;
}

.brand-name-small {
  max-width: 85px;
  display: block;
  font-size: 70%;
  text-align: center;
  color: theme-color("muted");
  @include text-truncate();
}
