/******************************************************************************/
/* BUTTONS                                                                    */
/******************************************************************************/

.btn.span-outline {
  opacity: 1.0;
  color: inherit;
  cursor: initial;
}

.btn.span {
  opacity: 1.0;
  cursor: initial;
}


.btn-group-gap {
  > *:not(:first-child) {
    @extend .ml-2;
  }
}

.no-outline {
  outline: none !important;
}

.no-focus {
  box-shadow: none !important;
}
